import { format } from 'date-fns';
import { DialoogProps } from 'dialoog';
import { useDialoog } from 'dialoog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as RepeatIcon } from '../../assets/icons/repeat.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/schedule.svg';
import { NOTIFICATION, RECURRENCE, RESERVATION_DEFAULT_INTERVAL } from '../../constants';
import { useDispatch } from '../../hooks/useDispatch';
import { intlKeys } from '../../localization-keys';
import { useCreateReservationMutation, useUpdateReservationMutation} from '../../services/reservations';
import { setContent, setMessage } from '../../states/messages';
import { Location, RecurrenceType, Reservation, AlertType } from '../../types';
import { getLocationHref } from '../../utils/getLocationHref';
import { Button } from '../forms/Button';
import { Form } from '../forms/Form';
import { LocationInfo } from '../reservation/LocationInfo';
import { RecurrenceOption } from '../reservation/RecurrenceOption';
import { ReservationOption } from '../reservation/ReservationOption';
import { SelectOption } from '../reservation/SelectOption';
import { TimeOption } from '../reservation/TimeOption';

import { LocationDetails } from './LocationDetails';
import styles from './NewReservation.module.scss';
import { SearchLocation } from './SearchLocation';
import { Dialog } from './base/Dialog';

type Props = {
  location: Location,
  date: Date,
  editReservation? : Reservation | null
};

export function NewReservation({ location, date, editReservation ,...props }: Props & DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const [createNewReservation] = useCreateReservationMutation();
  const [updateReservation] = useUpdateReservationMutation();
  const dispatch = useDispatch();
  const [recurrence, setRecurrence] = useState(false);
  const [updatedLocation, updateLocation] = useState(location);
  const [updatedDate, updateDate] = useState(date);
  const [recurrenceDate,setRecurrenceDate] = useState(updatedDate);  

  const func = (value :string) => {
    //TO DO EDIT SHOW & EDIT RECURRENCE
    setRecurrence(value!=='Does not repeat');
  };

  const setLocation = (item: Location) => {
    updateLocation(item);    
  };

  const setHour = (item: Date) => {
    updateDate(item);   
    setRecurrenceDate(item);    
  };

  const handleEditLocation= () => {
    if(editMode)
      open((props) => (
        <SearchLocation  onUpdate={setLocation} {...props}/>
      ));
  };

  const handleEditDate=()=>{
    if(editMode)
      open((props) => (
        <LocationDetails location={updatedLocation} reservationId = {editReservation.id} onUpdate={setHour} {...props}/>
      ));
  };

  const editMode  =  editReservation!==null && editReservation!==undefined;

  function displayErrorInfo(arg0: string) {
    dispatch(setMessage(true)) && dispatch(setContent(arg0));
  }
 
  return (
    <Dialog title={editMode ? t(intlKeys.UPDATE_RESERVATION): t(intlKeys.NEW_RESERVATION)}  {...props}>
      <div className={styles.header}>
        <LocationInfo location={updatedLocation}/>
      </div>
      <Form
        values={{
          startTime: format( updatedDate.getTime(), 'HH:mm'),
          endTime: format(new Date( 
            editMode  ? Math.max(editReservation.endTime.getTime(), updatedDate.getTime() + RESERVATION_DEFAULT_INTERVAL*60000 ) 
              : (updatedDate.getTime() + RESERVATION_DEFAULT_INTERVAL*60000)), 'HH:mm'),
          recurrence: editMode? editReservation.recurrence : RecurrenceType.DOES_NOT_REPEAT,
          notification:editMode? editReservation.notification : AlertType.NO_ALERT,
          noOfRecurrentReservations: editMode? editReservation.noOfRecurrentReservations : 0 ,
          availableEVSE:editMode? editReservation.locationSummaryDto.availableEvses : 0
        }}
        onSubmit={(values) => {
          if(values.availableEVSE === 0 ){           
            displayErrorInfo(t(intlKeys.NO_CONNECTORS_AT_LOCATION));
            return;
          }
          let payload = {
            id : editReservation?.id,
            startTime: `${format(updatedDate, 'dd/MM/yyyy')} ${values.startTime}`,
            endTime: `${format(updatedDate, 'dd/MM/yyyy')} ${values.endTime}`,
            locationId: updatedLocation.id,
            recurrence: values.recurrence,
            notification: values.notification,
            noOfRecurrentReservations: values.noOfRecurrentReservations
          };
          if (editMode) {
            updateReservation(payload).then(result => (Object.keys(result)[0] !== 'error') ? window.location.reload() : 
              displayErrorInfo(t(intlKeys.SOMETHING_WRONG)));
          }
          else   {
            createNewReservation(payload).then(result => (Object.keys(result)[0] !== 'error') ? window.location.reload() : 
              displayErrorInfo(t(intlKeys.SOMETHING_WRONG)));
          }
        }
        }
      >
        <div className={styles.wrapper}>
          <a  role="button" href="#emtpy" onClick={handleEditLocation} className={styles.editableArea}>
            <ReservationOption icon={<LocationIcon/>}>
              {updatedLocation.address.street}
              {!editMode &&
              ( <a href={getLocationHref(updatedLocation)} className={styles.anchor}>
                {t(intlKeys.GET_DIRECTIONS)}
              </a>)
              }
            </ReservationOption>
          </a>
          <a className={styles.editableArea}role="button" href="#emtpy" onClick= {handleEditDate}>
            <ReservationOption icon={<CalendarIcon className={styles.calendar}/>}>
              {format(updatedDate, 'EEEE, dd MMMM')}                                
            </ReservationOption>
          </a>
          <ReservationOption icon={<ScheduleIcon/>}>
            <TimeOption
              startLabel= {t(intlKeys.ARRIVAL_TIME)}
              startName="startTime"
              endLabel={t(intlKeys.DEPARTURE_TIME)}
              endName="endTime"
              location={updatedLocation}
              date={updatedDate}
              reservationId = {editReservation?.id}
            />
          </ReservationOption>
          <ReservationOption icon={<RepeatIcon/>}>
            <SelectOption
              title={t(intlKeys.RECURRING_RESERVATION)}
              defaultValue=  {editReservation?.recurrence}
              name={RECURRENCE}
              options={[[RecurrenceType.DOES_NOT_REPEAT, t(RecurrenceType.DOES_NOT_REPEAT)],
                [RecurrenceType.EVERY_WEEK, t(RecurrenceType.EVERY_WEEK)], 
                [RecurrenceType.EVERY_MONTH, t(RecurrenceType.EVERY_MONTH)]]}                        
              action={func}
            />
            {recurrence && 
              <RecurrenceOption
                reference={recurrenceDate}
              />
            }
          </ReservationOption>
          <ReservationOption icon={<BellIcon/>}>
            <SelectOption
              title={t(intlKeys.ALERT)}
              name= {NOTIFICATION}
              defaultValue={editReservation?.notification}
              options={[[AlertType.NO_ALERT, t(AlertType.NO_ALERT)], 
                [AlertType.TEN_MINUTES, t(AlertType.TEN_MINUTES)], 
                [AlertType.THIRTY_MINUTES, t(AlertType.THIRTY_MINUTES)], 
                [AlertType.ONE_HOUR, t(AlertType.ONE_HOUR)]]}
            />
          </ReservationOption>
         
          <Button
            text={editMode ? t(intlKeys.UPDATE): t(intlKeys.RESERVE) }
            type="submit"
            className={styles.button}                               
          />            
        </div>
      </Form>
    </Dialog>
  );
}