import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { unauthenticated } from '../middleware/unauthenticated';
import authenticationApi from '../services/authentication';
import chargeApi from '../services/charge';
import evsesApi from '../services/evses';
import locationsApi from '../services/locations';
import reservationsApi from '../services/reservations';
import authentication from '../states/authentication';
import charge from '../states/charge';
import messages from '../states/messages';

export const store = configureStore({
  reducer: {
    [authenticationApi.path]: authenticationApi.reducer,
    [locationsApi.path]: locationsApi.reducer,
    [reservationsApi.path]: reservationsApi.reducer,
    [authentication.path]: authentication.reducer,
    [charge.path]: charge.reducer,
    [messages.path]: messages.reducer,
    [evsesApi.path]: evsesApi.reducer,
    [chargeApi.path]: chargeApi.reducer
  },
  middleware: (get) => get()
    .concat(unauthenticated)
    .concat(authenticationApi.middleware)
    .concat(locationsApi.middleware)
    .concat(reservationsApi.middleware)
    .concat(evsesApi.middleware)
    .concat(chargeApi.middleware)
});

setupListeners(store.dispatch);
