import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { intlKeys } from './localization-keys';
import { State } from './types';

export const messages: Record<string, string> = {
  email: intlKeys.INVALID_EMAIL , //'Invalid e-mail',
  string: intlKeys.CANNOT_BE_EMPTY //'Cannot be empty'
};

export const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as State).authentication.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const transition = {
  type: 'spring',
  damping: 40,
  stiffness: 400
};

export const RESERVATION_DEFAULT_INTERVAL = 30;
export const NOTIFICATION = 'notification';
export const RECURRENCE = 'recurrence';
export const NO_OF_RECURRING_RESERVATIONS = 'noOfRecurrentReservations';
export const FEEDBACK_URL = 'https://form.typeform.com/to/g0uEfKZK';

