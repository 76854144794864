import { DialoogProps } from 'dialoog';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as FeedbackIcon } from '../../assets/icons/feedback.svg';
import { ReactComponent as LogOutIcon } from '../../assets/icons/logout.svg';
import { FEEDBACK_URL } from '../../constants';
import { intlKeys } from '../../localization-keys';
import { Button } from '../forms/Button';

import styles from './Settings.module.scss';
import { InlineDialog } from './base/InlineDialog';

export function Settings(props: DialoogProps) {
  const { t } = useTranslation();

  return (
    <InlineDialog className={styles.settings} {...props}>
      <div className={styles.header}>
        <button onClick={props.close}>
          <BackIcon className={styles.back}/>
        </button>
        {t(intlKeys.SETTINGS)}
      </div>
      <div className={styles.menuOption}>
        <LogOutIcon className={styles.feedback} />
        <Button text={t(intlKeys.LOGOUT)} className={styles.logout} onClick={
          () => {
            localStorage.removeItem('token');
            window.location.pathname = '/';
          }
        }/>
      </div>
      <div  className={styles.menuOption}>
        <a href= {FEEDBACK_URL} className={styles.anchor} >             
          <FeedbackIcon className={styles.feedback} />
          {t(intlKeys.GIVE_FEEDBACK)}   
        </a>
      </div>
    </InlineDialog>
  );
}
