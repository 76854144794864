import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../constants';
import { ReservedEvse } from '../types';

const evses = createApi({
  reducerPath: 'evsesApi',
  baseQuery,
  endpoints: ({ query }) => ({
    getEvses: query<Array<string>, ReservedEvse>({
      query: ({locationId, startTime, endTime}) => ({
        url: `evses/${locationId}/reserved?startTime=${startTime}&endTime=${endTime}`
      }),
    }),
    getMinEvseAvailable: query<number, ReservedEvse>({
      query: ({locationId, startTime, endTime , excludedReservationId}) => ({
        url: `evses/${locationId}/minEvses`,
        params: {
          startTime: startTime,
          endTime: endTime,
          excludedReservationId : excludedReservationId
        }
      }),
    })
  })
});

export const {
  useGetEvsesQuery,
  useGetMinEvseAvailableQuery,
} = evses;

export default {
  path: evses.reducerPath,
  reducer: evses.reducer,
  middleware: evses.middleware
};
