import { DialoogProps } from 'dialoog';
import { useTranslation } from 'react-i18next';

import { useDispatch } from '../../hooks/useDispatch';
import { intlKeys } from '../../localization-keys';
import { useDeleteReservationMutation} from '../../services/reservations';
import { setContent, setMessage } from '../../states/messages';
import { Reservation } from '../../types';
import { Button } from '../forms/Button';

import styles from './DeletePrompt.module.scss';
import { InlineDialog } from './base/InlineDialog';

type Props = {
  reservation: Reservation
};

export function DeletePrompt({ reservation, ...props }: Props & DialoogProps) {
  
  const { t } = useTranslation();
  const [deleteReservation] = useDeleteReservationMutation();
  const dispatch = useDispatch();
  const isReservationRecurrent = reservation.recurrence.valueOf() !== 'DOES_NOT_REPEAT';

  function handleErrors (result: Object) {

    if ((Object.keys(result)[0] === 'error'))
      dispatch(setMessage(true)) && dispatch(setContent(t(intlKeys.WRONG_CANCEL)));
  }

  return (
    <InlineDialog backdrop={true} className={styles.prompt} {...props}>
      <div className={styles.content}>
        <div className={styles.title}>{t(intlKeys.CANCEL_RESERVATION)}</div>
        
        <div className={styles.question}> {isReservationRecurrent && (t(intlKeys.RESERVATION_RECURRING))}
          {t(intlKeys.WANT_CANCEL)} {reservation.locationSummaryDto.name.trim()}? </div>
        
        <Button className={styles.cancelReserve}
          text={t(intlKeys.CANCEL_RESERVATION)}
          onClick={
            (values) => deleteReservation({ id : reservation.id, deleteRec: false}).then(
              (result) => {                
                handleErrors(result);                
                props.close();
              }
            )
          }
        />
        {isReservationRecurrent && (
          <Button className={styles.cancelRec}
            text={t(intlKeys.CANCEL_RECURRENCE)}
            onClick={
              (values) => deleteReservation({id : reservation.id, deleteRec: true}).then(
                (result) => {

                  handleErrors(result);      
                  props.close();
                }
              )
            }
          />
        )
        }
        <Button  text={t(intlKeys.KEEP_RESERVATION)} className={styles.keep} onClick={props.close} />
      </div>
    </InlineDialog>
  );

}
