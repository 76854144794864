import { useDialoog } from 'dialoog';
import React, { useEffect, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Spinner } from '../components/Spinner';
import { InstallPrompt } from '../components/dialogs/InstallPrompt';

import { Home } from './Home';
import { Login } from './Login';
import { Password } from './Password';
import styles from './Root.module.scss';
import { StatusWrapper } from './StatusWrapper';
import { Upcoming } from './Upcoming';

export function Root() {
  const [, { open }] = useDialoog();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();

      open((props) => (
        <InstallPrompt event={event as BeforeInstallPromptEvent} {...props}/>
      ));
    });
  }, [open]);

  return (
    <div >
      <Suspense fallback={ 
        <div className={styles.loader}>
          <div className={styles.logo}/>
          <Spinner/>
        </div>}>  
        <Routes>
          <Route path="/password" element={<Password/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route element={<StatusWrapper/>}>
            <Route path="/" element={<Home/>}/>
            <Route path="/upcoming" element={<Upcoming/>}/>
          </Route>
        </Routes>
      </Suspense> 
    </div>
  );
}
