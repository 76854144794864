import { addMonths, addWeeks, getWeekOfMonth, isSameDay } from 'date-fns';
import {
  AnimatePresence,
  motion,
  MotionValue,
  useAnimation,
  useDomEvent,
  useMotionValue,
  useTransform
} from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';

import { transition } from '../../constants';
import { useTimeout } from '../../hooks/useTimeout';
import { cs } from '../../utils/cs';
import { getMonth } from '../../utils/getMonth';

import styles from './Calendar.module.scss';
import { CalendarDate } from './CalendarDate';
import { Weekdays } from './Weekdays';

const daySize = 36;
const totalSize = daySize * 5;

type Props = {
  reservations?: Date[],
  selected: Date,
  setSelected: (date: Date) => void,
  visible: Date,
  setVisible: (date: Date) => void,
  progress?: MotionValue<number>,
  className?: string
};

export function Calendar({
  reservations = [],
  selected,
  setSelected,
  visible,
  setVisible,
  progress: progressWatcher,
  className
}: Props) {
  const animation = useAnimation();
  const [axis, setAxis] = useState('');
  const [direction, setDirection] = useState(0);
  const month = useMemo(() => getMonth(visible), [visible]);
  const week = useMemo(() => getWeekOfMonth(visible, { weekStartsOn: 1 }) - 1, [visible]);
  const x = useMotionValue(-window.innerWidth);
  const y = useMotionValue(-document.documentElement.offsetHeight - totalSize);
  const inverseY = useTransform(y, (value) => -value - document.documentElement.offsetHeight);
  const inverseX = useTransform(x, (value) => -value);
  const progress = useTransform(y, [-document.documentElement.offsetHeight - totalSize, -document.documentElement.offsetHeight], [0, 1]);
  const monthY = useTransform(progress, [0, 1], [totalSize - daySize * week, 0]);

  useEffect(() => progressWatcher && progress.onChange((value) => progressWatcher.set(value)), [progress, progressWatcher]);

  const windowRef = useRef(window);
  const [constraints, setConstraints] = useState({
    left: -window.innerWidth,
    right: -window.innerWidth,
    top: -document.documentElement.offsetHeight - totalSize,
    bottom: -document.documentElement.offsetHeight
  });
  y.set(-document.documentElement.offsetHeight - totalSize);
  useTimeout(500, ()=> {
    setConstraints({
      left: -window.innerWidth,
      right: -window.innerWidth,
      top: -document.documentElement.offsetHeight - totalSize,
      bottom: -document.documentElement.offsetHeight
    });
  }, []);
  useDomEvent(windowRef, 'resize', () => {
    animation.set({
      x: -window.innerWidth,
      y: -document.documentElement.offsetHeight - totalSize
    });
    setConstraints({
      left: -window.innerWidth,
      right: -window.innerWidth,
      top: -document.documentElement.offsetHeight - totalSize,
      bottom: -document.documentElement.offsetHeight
    });
  });

  return (
    <motion.div
      className={cs(className, styles.calendar)}
      style={{ x, y }}
      animate={animation}
      transition={transition}
      drag={true}
      dragDirectionLock={true}
      dragConstraints={constraints}
      dragElastic={.2}
      onDirectionLock={setAxis}
      onDragEnd={(event, { offset, velocity }) => {
        const p = progress.get();

        if (axis === 'y' || (p !== 0 && p !== 1)) {
          animation.set({
            x: -window.innerWidth,
            y: -document.documentElement.offsetHeight - velocity.y > 20 ? 0 : totalSize
          });
        }

        if (axis !== 'x') {
          return;
        }

        const power = Math.abs(offset.x) * velocity.x;
        const add = p > 0 ? addMonths : addWeeks;

        if (power > 10000) {
          setDirection(-1);
          setVisible(add(visible, -1));
        } else if (power < -10000) {
          setDirection(1);
          setVisible(add(visible, 1));
        }
      }}
    >
      <Weekdays
        inverseX={inverseX}
        inverseY={inverseY}
      />
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={visible.getTime()}
          className={styles.month}
          style={{
            y: monthY
          }}
          transition={transition}
          variants={{
            enter: (direction: number) => ({
              x: `${100 * direction}%`
            }),
            visible: {
              x: 0
            },
            leave: (direction: number) => ({
              position: 'absolute',
              x: `${100 * -direction}%`
            })
          }}
          initial="enter"
          animate="visible"
          exit="leave"
          custom={direction}
        >
          {month.map((date) => (
            <CalendarDate
              key={date.getTime()}
              date={date}
              visible={visible}
              selected={date.getTime() === selected.getTime()}
              reserved={reservations.some((d) => isSameDay(d, date))}
              progress={progress}
              setSelected={() => setSelected(date)}
            />
          ))}
        </motion.div>
      </AnimatePresence>
      <motion.div
        className={styles.pill}
        style={{
          x: inverseX,
          y: 1
        }}
      />
    </motion.div>
  );
}
