import { format } from 'date-fns';
import { useDialoog } from 'dialoog';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';

import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as PalmIcon } from '../assets/icons/palm.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { EmptyList } from '../components/EmptyList';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';
import { Spinner } from '../components/Spinner';
import { Charging } from '../components/charging/Charging';
import { SessionCompleted } from '../components/charging/SessionCompleted';
import { ReservationDetails } from '../components/dialogs/ReservationDetails';
import { Settings } from '../components/dialogs/Settings';
import { Dialog } from '../components/dialogs/base/Dialog';
import { MessageDialog } from '../components/dialogs/base/MessageDialog';
import { ReservationCard } from '../components/reservation/ReservationCard';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { intlKeys } from '../localization-keys';
import { useGetReservationsQuery } from '../services/reservations';
import { selectToken } from '../states/authentication';
import { setCollapsed, toggleChargingScreen, selectChargingFinished,
  toggleChargingFinished, selectStoppedReservation, toggleCharging, setChargingScreen, setStartedReservation, selectStartedReservation } from '../states/charge';
import { selectMessage } from '../states/messages';
import { Reservation, ReservationStatus } from '../types';
import { groupData } from '../utils/groupData';

import styles from './Upcoming.module.scss';

export function Upcoming() {
  const [t] = useTranslation();
  const [, { open }] = useDialoog();
  
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  //const [generatedUnique, setGeneratedUnique] = useState<number>(new Date().getTime());
  const [connectedTime, setConnectedTime] = useState<string>('0:00');
  const message = useSelector(selectMessage);
  const chargingFinished = useSelector(selectChargingFinished);
  const chargingReservation = useSelector(selectStartedReservation);
  const [activeReservationId , setActiveReservationId] = useState(chargingReservation.id);
  
  const [ref, { loading, items }] = useInfiniteScroll('0px 0px 400px 0px', useGetReservationsQuery);
  
  const grouped = useMemo(() => groupData(items, 'startTime', (v) => v.toDateString()), [items]);

  const updateConnectedTime = (value: string) => {
    setConnectedTime(value);
  };

  useEffect(()=>{
    setActiveReservationId(chargingReservation.id);
  }, [chargingReservation]);
  
  useEffect(() => {
    const newReservationData = items ? items : reservations;
    const anyCharging = newReservationData.filter((reservation) => reservation.status === ReservationStatus.CHARGING) ?? [];
    if (!anyCharging.length) {
      dispatch(setChargingScreen(false));
    } else {
      dispatch(setStartedReservation(anyCharging[0]));
    }
    setReservations(newReservationData);
  }, [dispatch, items, reservations]);

  const stoppedReservation = useSelector(selectStoppedReservation);
  useEffect(() => {
    if (!chargingFinished) return;
    dispatch(open.c((props) => (
      <Dialog title={<span><p>{stoppedReservation.locationSummaryDto.address.street},</p> <p> {t(intlKeys.CHARGER_ID)} {stoppedReservation.chargerId}</p></span>} 
        onClosePopup={() => {dispatch(toggleChargingFinished()); dispatch(toggleCharging());}} {...props}>
        <div className={styles.sessionCompletedWrapper}>
          <SessionCompleted reservation={stoppedReservation} onClose={() => { 
            dispatch(toggleChargingFinished()); 
            props.close(); 
            dispatch(toggleCharging());
          }}/>
        </div>
      </Dialog>
    )));
  }, [chargingFinished, stoppedReservation, dispatch, open,t]);
  
  if (!token) {
    return (
      <Navigate to="/login"/>
    );
  }
  const startSessionAction = (reservation: Reservation) => {
    dispatch(setStartedReservation(reservation)); 
    //setGeneratedUnique(new Date().getTime()); // used to prefetch once again the reservations
    setTimeout(()=> {
      dispatch(setCollapsed(false));
    }, 500);
  };
  
  return (
    <>
      <Header
        text= {t(intlKeys.UPCOMING_R)}
        actions={[{
          icon: <CalendarIcon/>,
          onClick: '/'
        }, {
          icon: <SettingsIcon/>,
          onClick: open.c((props) => (
            <Settings {...props}/>
          ))
        }]}
      />
      <div className={styles.container}>
        {Object.entries(grouped).map(([group, entries]) => (
          <Fragment key={group}>
            <div className={styles.date}>
              {format(entries[0].startTime, 'dd MMMM yyyy')}
            </div>
            {entries.map((reservation, i) => (
              <div key={i} >
                <ReservationCard
                  connectedTime={connectedTime}
                  reservation={reservation}
                  className={styles.charging}
                  onClick={reservation.status === 'CHARGING' ? () => {dispatch(setCollapsed(false)); dispatch(toggleChargingScreen()); } : (reservation.status === 'CHARGED' ? 
                    open.c((props) => (
                      <Dialog title={<span><p> {reservation.locationSummaryDto.address.street}, </p> <p> {t(intlKeys.CHARGER_ID)} {reservation.chargerId}</p></span>}
                        {...props}>
                        <div className={styles.sessionCompletedWrapper}>
                          <SessionCompleted reservation={reservation} onClose={props.close}/>
                        </div>
                      </Dialog>
                    )) : open.c((props) => (
                      reservation.status === 'PENDING' ? (
                        <ReservationDetails reservation={reservation} startingSession={startSessionAction} {...props}/> 
                      ) : (
                        <Dialog title={`${reservation.locationSummaryDto.address.street}`}
                          {...props}
                        >
                          <div className={styles.willBeActivewrap}>
                            {reservation.status === ReservationStatus.TO_BE_CHARGED ? (
                              <p className={styles.willBeActive}>
                                {t(intlKeys.RESERVATION_FUTURE_SUCCESS)}
                              </p>
                            ) : (
                              <p className={styles.willBeActive}>
                                {t(intlKeys.RESERVATION_FUTURE_FAIL)}
                              </p>
                            )}
                          </div>
                        </Dialog>
                      )
                    )))}
                />
                {(reservation.id === activeReservationId) && <Charging reservation={chargingReservation} setConnectedTime={updateConnectedTime}/>}
              </div>
            ))}
            {message && <MessageDialog/>}
            {false && <Loading text='text'/>}
          </Fragment>
        )) || (
          <EmptyList icon={<PalmIcon/>}>
            {t(intlKeys.NO_UPCOMING)}<br/>{t(intlKeys.TAP_ADD)}
          </EmptyList>
        )}
        {loading && (
          <div className={styles.spinner} ref={ref}>
            <Spinner/>
          </div>
        )}
      </div>
    </>
  );
}

