import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/Jibe_LOGO.svg';
import { Button } from '../components/forms/Button';
import { Form } from '../components/forms/Form';
import { Input } from '../components/forms/Input';
import { intlKeys } from '../localization-keys';
import { useSetPasswordMutation } from '../services/authentication';

import styles from './Password.module.scss';

export function Password() {
  const { t } = useTranslation();
  const [setPassword] = useSetPasswordMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div className={styles.pageContainer}>
      <Form
        className={styles.container}
        values={{
          password: '',
          confirmPassword: ''
        }}
        onSubmit={(values) =>
          setPassword({
            newPassword: values.password,
            confirmNewPassword: values.confirmPassword,
            token: `${searchParams.get('token')}`
          }).then(result => (Object.keys(result)[0] !== 'error') && navigate('/'))}
      >
        <Logo className={styles.logo}/>
        <p className={styles.title}>set password</p>
        <Input type="password" name="password" label={t(intlKeys.NEW_PASSWORD)}/>
        <Input type="password" name="confirmPassword" label={t(intlKeys.CONFIRM_PASSWORD)}/>
        <Button type="submit" text={t(intlKeys.SET_PASSWORD)} className={styles.button}/>
      </Form>
    </div>
  );
}
