import { format } from 'date-fns';
import { useDialoog } from 'dialoog';
import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as FaultyIcon } from '../../assets/icons/faulty.svg';
import { intlKeys } from '../../localization-keys';
import { Reservation, ReservationStatus } from '../../types';
import { cs } from '../../utils/cs';
import { DeletePrompt } from '../dialogs/DeletePrompt';
import { NewReservation } from '../dialogs/NewReservation';

import styles from './ReservationCard.module.scss';

type Props = {
  reservation: Reservation
  connectedTime: string
};

export function ReservationCard({ reservation, connectedTime, ...props }: Props & ComponentPropsWithoutRef<typeof motion.button>) {

  const { t } = useTranslation();

  const handleDelete = () => open((props) => (
    // eslint-disable-next-line no-restricted-globals
    <DeletePrompt  reservation={reservation} {...props} />
  ));

  const handleEdit = () => open((props) => (
    // eslint-disable-next-line no-restricted-globals
 
    <NewReservation location={reservation.locationSummaryDto} date={reservation.startTime} editReservation={reservation} {...props}/>
 
  ));

  const [, { open }] = useDialoog();
  const charging = reservation.status === ReservationStatus.CHARGING;
  const charged = reservation.status === ReservationStatus.CHARGED;
  const notCharged = reservation.status === ReservationStatus.NOT_CHARGED;
  const pending = reservation.status === ReservationStatus.PENDING;
  const toBeCharged = reservation.status === ReservationStatus.TO_BE_CHARGED;
  const failed = reservation.status === ReservationStatus.FAILED;
  return (
    <motion.button
      {...props}
      className={cs(props.className, styles.card, {
        [styles.charging]: charging,
        [styles.charged]: charged,
        [styles.notCharged]: notCharged,
        [styles.failed]: failed,
        [styles.pending]: pending
      })}
      whileTap={{ scale: .95 }}
      disabled={notCharged && true}
    >
      <div className={styles.top}>
        <span className={styles.location}>
          {reservation.locationSummaryDto.name}
        </span>
        {(toBeCharged || pending)  &&
          <a role="button" href="#emtpy" onClick=
            { e =>  {e.stopPropagation(); 
              handleDelete(); }
            }>
            <DeleteIcon className={styles.iconWrapper} />
          </a>
        }
      </div>
      <span className={styles.city}>
        {reservation.locationSummaryDto.address.street}
      </span>
      <div className={styles.details}>
        {charging && (<span>{connectedTime} {t(intlKeys.CHARGING)}</span>)}
        {charged && (<span>{t(intlKeys.CHARGED)}</span>)}
        {notCharged && (<span><span className={styles.iconWrapper}><ErrorIcon /></span>{t(intlKeys.NOT_CHARGED)}</span>)}
        {failed && (<span><span className={styles.iconWrapper}><FaultyIcon /></span> {t(intlKeys.FAILED)}</span>)}
        {(!charging || failed || charged) && (<span>{format(reservation.startTime, 'HH:mm')} - {format(reservation.endTime, 'HH:mm')}</span>)}
        {pending && <ArrowIcon className={styles.arrow} />}
        {toBeCharged && 
        <a role="button" href="#emtpy" onClick=
          { e =>  {e.stopPropagation(); 
            handleEdit(); }
          }>
          <EditIcon className={styles.edit} />
        </a>
        }
      </div>
    </motion.button>

  );

}
