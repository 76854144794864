import { createApi } from '@reduxjs/toolkit/query/react';
import { addYears, endOfDay, format, startOfDay } from 'date-fns';

import { baseQuery } from '../constants';
import { Paginated, Reservation, Raw, UpdateReservation, DeleteReservationArgs } from '../types';
import { parseDate } from '../utils/parseDate';

const transformResponse = (response: Raw<Reservation>) => ({
  ...response,
  startTime: parseDate(response.startTime),
  endTime: parseDate(response.endTime)
});

const reservations = createApi({
  reducerPath: 'reservationsApi',
  baseQuery,
  refetchOnReconnect: true,
  tagTypes: ['Reservation'],
  endpoints: ({ query, mutation }) => ({
    getReservations: query<Paginated<Reservation>, { page: number, start?: number, end?: number, generated?:number }>({
      query: ({ page, start, end, generated }) => ({
        url: 'reservations',
        params: {
          page,
          pageSize: 100,
          startTime: format(start ?? startOfDay(new Date()), 'dd/MM/yyyy HH:mm'),
          endTime: format(end ?? addYears(endOfDay(new Date()), 5), 'dd/MM/yyyy HH:mm'),
          generated,
        },
        invalidatesTags: ['Reservation'],
      }),
      providesTags: (result) => [
        ...result?.items.map(({ id }) => ({
          type: 'Reservation' as const,
          id
        })) ?? [],
        { type: 'Reservation', id: '*' }
      ],
      transformResponse: (response: Paginated<Raw<Reservation>>) => ({
        ...response,
        items: response.items.map((item) => ({
          ...item,
          startTime: parseDate(item.startTime),
          endTime: parseDate(item.endTime)
        }))
      })
    }),
    getReservation: query<Reservation, number>({
      query: (id) => `reservations/${id}`,
      providesTags: (result, error, id) => [{ type: 'Reservation', id }],
      transformResponse
    }),
    createReservation: mutation<Reservation, UpdateReservation>({
      query: (body) => ({
        url: 'reservations',
        method: 'post',
        body
      }),
      invalidatesTags: [{ type: 'Reservation', id: '*' }],
      transformResponse
    }),
    updateReservation: mutation<Reservation, UpdateReservation>({
      query: (body) => ({
        url: 'reservations',
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Reservation', id }],
      transformResponse
    }),
    deleteReservation: mutation<Reservation, DeleteReservationArgs>({
      query: (deleteArgs) => ({
        url: `reservations/${deleteArgs.id}`,
        method: 'delete',
        body: {'deleteAllRecurrences': deleteArgs.deleteRec}
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Reservation', id }],
      transformResponse
    })
  })
});

export const {
  useGetReservationsQuery,
  useGetReservationQuery,
  useCreateReservationMutation,
  useUpdateReservationMutation,
  useDeleteReservationMutation
} = reservations;

export default {
  path: reservations.reducerPath,
  reducer: reservations.reducer,
  middleware: reservations.middleware
};
