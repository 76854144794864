import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from '../types';

const authentication = createSlice({
  name: 'authentication',
  initialState: {
    token: localStorage.getItem('token') ?? undefined
  },
  reducers: {
    login: (state, payload: PayloadAction<string>) => {
      localStorage.setItem('token', payload.payload);

      state.token = payload.payload;
    },
    logout: (state) => {
      localStorage.removeItem('token');

      state.token = undefined;
    }
  }
});

export const {
  login,
  logout
} = authentication.actions;

export function selectToken(state: State) {
  return state.authentication.token;
}

export default {
  path: authentication.name,
  reducer: authentication.reducer
};
