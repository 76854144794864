import 'normalize.css';
import { Dialoog, DialoogProvider } from 'dialoog';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Root } from './pages/Root';
import { registerWorker } from './utils/registerWorker';
import { store } from './utils/store';

import './index.scss';
import './i18n';

render((
  <Provider store={store}>
    <DialoogProvider>
      <BrowserRouter>
        <Root/>
        <Dialoog/>
      </BrowserRouter>
    </DialoogProvider>
  </Provider>
), document.getElementById('root'));

registerWorker(console.log);
