import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

export const unauthenticated: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  return next(action);
};
