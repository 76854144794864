import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { object, size, string } from 'superstruct';

import { Button } from '../components/forms/Button';
import { Form } from '../components/forms/Form';
import { Input } from '../components/forms/Input';
import { useDispatch } from '../hooks/useDispatch';
import { intlKeys } from '../localization-keys';
import { useLoginMutation } from '../services/authentication';
import { login } from '../states/authentication';
import { Email } from '../structs/Email';

import styles from './Login.module.scss';

const struct = object({
  email: Email,
  password: size(string(), 1, Infinity)
});

export function Login() {
  const [mutate, { data }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!data) {
      return;
    }

    dispatch(login(data));
    navigate('/');
  }, [data, dispatch, navigate]);

  return (
    <Form
      struct={struct}
      onSubmit={(values) => mutate(values)}
    >
      <div className={styles.page}>
        <div className={styles.header}>
          <div className={styles.logo}/>
        </div>
        <Input name="email" label={t(intlKeys.EMAIL)} type="email"/>
        <Input name="password" label={t(intlKeys.PASSWORD)} type="password"/>
        <Button text={t(intlKeys.FORGOT_PWD)} className={styles.forgot} type="button" outlined={true}/>
        <div className={styles.spacer}/>
        <Button text={t(intlKeys.LOGIN)} type="submit"/>
      </div>
    </Form>
  );
}
