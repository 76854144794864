import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../constants';

const authentication = createApi({
  reducerPath: 'authenticationApi',
  baseQuery,
  endpoints: ({ mutation }) => ({
    login: mutation<string | null, {
      email: string,
      password: string
    }>({
      query: ({ email, password }) => ({
        url: 'login',
        method: 'post',
        body: {
          username: email,
          password
        }
      }),
      transformResponse: ({ token }) => token
    }),
    setPassword: mutation<string | null, {
      newPassword: string,
      confirmNewPassword: string
      token: string,
    }>({
      query: ({ newPassword, confirmNewPassword, token }) => ({
        url: `user/password?token=${token}`,
        method: 'post',
        body: {
          newPassword,
          confirmNewPassword
        }
      })
    })
  })
});

export const {
  useLoginMutation,
  useSetPasswordMutation
} = authentication;

export default {
  path: authentication.reducerPath,
  reducer: authentication.reducer,
  middleware: authentication.middleware
};
