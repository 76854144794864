import { DialoogProps, useDialoog } from 'dialoog';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyStarIcon } from '../../assets/icons/emptyStar.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { useDebounce } from '../../hooks/useDebounce';
import { intlKeys } from '../../localization-keys';
import { useGetLocationsQuery } from '../../services/locations';
import { Location } from '../../types';
import { ListPreview } from '../ListPreview';
import { Spinner } from '../Spinner';
import { Search } from '../forms/Search';

import { LocationDetails } from './LocationDetails';
import styles from './SearchLocation.module.scss';
import { Dialog } from './base/Dialog';
import { InlineDialog } from './base/InlineDialog';

type Props = {
   onUpdate? : Function   
};

export function SearchLocation({onUpdate, ...props }: Props & DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 250);
  const { data, isLoading } = useGetLocationsQuery(debouncedSearch, {
    skip: !debouncedSearch
  });

  const editMode = onUpdate!=null && onUpdate!==undefined;

  const onClickLocation = (item: Location) =>{
    if(editMode) {
      onUpdate(item);
      props.close();    
    }
    else {
      open((props) => (
        <LocationDetails location={item} {...props}/>
      ));
    }  
  };

  const renderItem = (item: Location) => (
    <button
      className={styles.item}
      onClick={ ()=>
        onClickLocation(item)      
      }
    >
      {item.favorite ? (
        <StarIcon className={styles.icon}/>
      ) : (
        <EmptyStarIcon className={styles.icon}/>
      )}
      {item.name}
    </button>
  );

  return (
    <Dialog title={editMode? t(intlKeys.UPDATE_RESERVATION): t(intlKeys.NEW_RESERVATION)} {...props}>
      <div className={styles.wrapper}>
        <Search
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t(intlKeys.WHERE_TO)} 
        />
        <div className={styles.lists}>
          <ListPreview
            name={t(intlKeys.FAVORITES)} 
            keyBy="id"
            items={data?.items ?? []}
            loadAll={() => data?.items ?? []}
          >
            {renderItem}
          </ListPreview>
          <ListPreview
            name={t(intlKeys.RECENTS)}
            keyBy="id"
            items={data?.items ?? []}
            loadAll={() => data?.items ?? []}
          >
            {renderItem}
          </ListPreview>
        </div>
        <InlineDialog
          title={t(intlKeys.WHERE_TO)} 
          open={Boolean(search)}
          close={() => setSearch('')}
          remove={() => {}}
          index={0}
          className={styles.results}
        >
          {data?.items.map((reservation) => (
            <Fragment key={reservation.id}>
              {renderItem(reservation)}
            </Fragment>
          ))}
          {isLoading && (
            <Spinner/>
          )}
        </InlineDialog>
      </div>
    </Dialog>
  );
}
