import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
 
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';

//new translations file will be imported and added in the below dict
const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
};

i18n 
  .use(LanguageDetector)  
  .use(initReactI18next)
  .init({    
    fallbackLng: 'en',   
    resources,
    debug: false,    
    interpolation: {      
      escapeValue: false,        
    } 
  }).then((err)=>console.log('something went wrong when loading', err));

export default i18n;