import { useDialoog } from 'dialoog';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrowDown.svg';
import { cs } from '../../utils/cs';
import { Select } from '../forms/Select';

import styles from './SelectOption.module.scss';

type Props = {
  name: string,
  options: string[][],
  title: string,
  defaultValue?: string,
  dropDown?: boolean,
  action?: Function
};

export function SelectOption({ name, options, defaultValue, title, dropDown, action }: Props) {
  const [, { open }] = useDialoog();
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  return (
    <button
      type="button"
      className={cs(styles.button, {
        [styles.inactive]: !dropDown && value === options[0][0],
        [styles.dropDown]: dropDown === true
      })}
      onClick={open.c((props) => (
        <Select
          title={title}
          options={options}
          setOption={(value) => {
            setValue(name, value) ; 
            if(action!==undefined ) {
              action(value); 
            } }}          
          {...props}
        />
      ))}
    >
      {options.find(([key]) => key === value)?.[1] ?? (value !== '' ? value : defaultValue)}
      {dropDown && <ArrowDownIcon/>}
    </button>
  );
}
