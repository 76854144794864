import { endOfMonth, format, isSameDay, startOfDay, startOfMonth } from 'date-fns';
import { useDialoog } from 'dialoog';
import { motion, useMotionValue } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';

import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import { ReactComponent as PalmIcon } from '../assets/icons/palm.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { EmptyList } from '../components/EmptyList';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';
import { Calendar } from '../components/calendar/Calendar';
import { Charging } from '../components/charging/Charging';
import { SessionCompleted } from '../components/charging/SessionCompleted';
import { ReservationDetails } from '../components/dialogs/ReservationDetails';
import { Settings } from '../components/dialogs/Settings';
import { Dialog } from '../components/dialogs/base/Dialog';
import { MessageDialog } from '../components/dialogs/base/MessageDialog';
import { ReservationCard } from '../components/reservation/ReservationCard';
import { useDispatch } from '../hooks/useDispatch';
import { useSelector } from '../hooks/useSelector';
import { intlKeys } from '../localization-keys';
import { useGetReservationsQuery } from '../services/reservations';
import { selectToken } from '../states/authentication';
import { setCollapsed, toggleChargingScreen, selectChargingFinished,
  toggleChargingFinished, selectStoppedReservation, toggleCharging, setChargingScreen, setStartedReservation, selectStartedReservation } from '../states/charge';
import { selectMessage } from '../states/messages';
import { Reservation, ReservationStatus } from '../types';
 
import styles from './Home.module.scss';

export function Home() {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const progress = useMotionValue(0);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [selected, setSelected] = useState(() => startOfDay(new Date()));
  const [visible, setVisible] = useState(selected);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [generatedUnique, setGeneratedUnique] = useState<number>(new Date().getTime());
  const [connectedTime, setConnectedTime] = useState<string>('0:00');
  const message = useSelector(selectMessage);
  const chargingFinished = useSelector(selectChargingFinished);
  const chargingReservation = useSelector(selectStartedReservation);
  const [activeReservationId , setActiveReservationId] = useState(chargingReservation.id);
  const { data } = useGetReservationsQuery({
    page: 0,
    generated: generatedUnique,
    start: startOfMonth(visible).getTime(),
    end: endOfMonth(visible).getTime()
  }, { skip: !token, refetchOnMountOrArgChange: true, pollingInterval: 30000 });

  const updateConnectedTime = (value: string) => {
    setConnectedTime(value);
  };

  useEffect(()=>{
    setActiveReservationId(chargingReservation.id);
  }, [chargingReservation]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const newReservationData = data ? data.items : reservations;
    const anyCharging = newReservationData.filter((reservation) => reservation.status === ReservationStatus.CHARGING) ?? [];
    if (!anyCharging.length) {
      dispatch(setChargingScreen(false));
    } else {
      dispatch(setStartedReservation(anyCharging[0]));
    }
    setReservations(data ? data.items : reservations);
  }, [data, reservations,dispatch]);

  const stoppedReservation = useSelector(selectStoppedReservation);
  useEffect(() => {
    if (!chargingFinished) return;
    dispatch(open.c((props) => (
      <Dialog title={<span><p>{stoppedReservation.locationSummaryDto.address.street},</p> <p> {t(intlKeys.CHARGER_ID)} {stoppedReservation.chargerId}</p></span>} 
        onClosePopup={() => {dispatch(toggleChargingFinished()); dispatch(toggleCharging());}} {...props}>
        <div className={styles.sessionCompletedWrapper}>
          <SessionCompleted reservation={stoppedReservation} onClose={() => { 
            dispatch(toggleChargingFinished()); 
            props.close(); 
            dispatch(toggleCharging());
          }}/>
        </div>
      </Dialog>
    )));
  }, [chargingFinished, stoppedReservation, dispatch, open,t]);
  
  if (!token) {
    return (
      <Navigate to="/login"/>
    );
  }
  const startSessionAction = (reservation: Reservation) => {
    dispatch(setStartedReservation(reservation)); 
    setGeneratedUnique(new Date().getTime()); // used to prefetch once again the reservations
    setTimeout(()=> {
      dispatch(setCollapsed(false));
    }, 500);
  };

  const filtered = reservations.filter((reservation) => isSameDay(reservation.startTime, selected)) ?? [];
  
  return (
    <>
      <Header
        text = {selected.getDate() ===  new Date().getDate()? t(intlKeys.RESERVATIONS) : visible.toLocaleString('default', { month: 'long' }) }
        actions={[{
          icon: <ListIcon/>,
          onClick: '/upcoming'
        }, {
          icon: <SettingsIcon/>,
          onClick: open.c((props) => (
            <Settings {...props}/>
          ))
        }]}
      >
        <Calendar
          reservations={reservations.map((reservation) => reservation.startTime) ?? []}
          selected={selected}
          setSelected={setSelected}
          visible={visible}
          setVisible={setVisible}
          progress={progress}
          className={styles.calendar}
        />
      </Header>
      <motion.div
        className={styles.month}
        style={{
          opacity: progress
        }}
      >
        {format(visible, 'MMMM')}
      </motion.div>
      <div className={styles.container}>
        {filtered.length ? filtered.map((reservation, i) => (
          <div key={i} >
            <ReservationCard
              connectedTime={connectedTime}
              reservation={reservation}
              className={styles.charging}
              onClick={reservation.status === 'CHARGING' ? () => {dispatch(setCollapsed(false)); dispatch(toggleChargingScreen()); } : (reservation.status === 'CHARGED' ? 
                open.c((props) => (
                  <Dialog title={<span><p> {reservation.locationSummaryDto.address.street}, </p> <p> {t(intlKeys.CHARGER_ID)} {reservation.chargerId}</p></span>}
                    {...props}>
                    <div className={styles.sessionCompletedWrapper}>
                      <SessionCompleted reservation={reservation} onClose={props.close}/>
                    </div>
                  </Dialog>
                )) : open.c((props) => (
                  reservation.status === 'PENDING' ? (
                    <ReservationDetails reservation={reservation} startingSession={startSessionAction} {...props}/> 
                  ) : (
                    <Dialog title={`${reservation.locationSummaryDto.address.street}`}
                      {...props}
                    >
                      <div className={styles.willBeActivewrap}>
                        {reservation.status === ReservationStatus.TO_BE_CHARGED ? (
                          <p className={styles.willBeActive}>
                            {t(intlKeys.RESERVATION_FUTURE_SUCCESS)}
                          </p>
                        ) : (
                          <p className={styles.willBeActive}>
                            {t(intlKeys.RESERVATION_FUTURE_FAIL)}
                          </p>
                        )}
                      </div>
                    </Dialog>
                  )
                )))}
            />
            {(reservation.id === activeReservationId) && <Charging reservation={chargingReservation} setConnectedTime={updateConnectedTime}/>}
          </div>
        )) : (
          <EmptyList icon={<PalmIcon/>}>
            {t(intlKeys.NO_RESERVATIONS_FOR)} {format(selected, 'PP')}.<br/>{t(intlKeys.TAP_ADD)}
          </EmptyList>
        )}
        {message && <MessageDialog/>}
        {false && <Loading text='text'/>}
      </div>
    </>
  );
}
