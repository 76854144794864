import { startOfDay, format, compareAsc } from 'date-fns';
import { DialoogProps, useDialoog } from 'dialoog';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { intlKeys } from '../../localization-keys';
import { useGetLocationQuery } from '../../services/locations';
import { Location } from '../../types';
import { Timeline } from '../Timeline';
import { Calendar } from '../calendar/Calendar';
import { LocationInfo } from '../reservation/LocationInfo';

import styles from './LocationDetails.module.scss';
import { NewReservation } from './NewReservation';
import { Dialog } from './base/Dialog';

type Props = {
  location: Location
  onUpdate?: Function
  reservationId?:number
};

export function LocationDetails({ location,onUpdate,reservationId, ...props }: Props & DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const [selected, setSelected] = useState(() => startOfDay(new Date()));
  const [visible, setVisible] = useState(selected);
  const [hoursAvailable, setHoursAvailable] = useState(new Array<{hour:string, total:number, available:number}>());
  const pastDate = compareAsc(selected, new Date().setHours(0,0,0,0)) === -1;
  const { data } = useGetLocationQuery({
    id: location.id, 
    day: `${ format(selected ?? startOfDay(new Date()), 'yyyy-MM-dd') +'T00:00:00'}.000Z`,
    excludedReservationId : reservationId?? 0
  }, {
    skip: pastDate,
    refetchOnMountOrArgChange: true
  });

  const editMode = onUpdate!=null && onUpdate!==undefined;

  useEffect(() => {
    if (!data?.availableEvses) {
      return;
    }
    let availability = Object.values(data?.availableEvses);
    let index = 0;
    // const groupByHour = new Map<number, number>();
    // for(let objKey of Object.keys(data?.availableEvses)) {
    //   let [, secondPart] = objKey.split('T');
    //   let [hourStr, ] = secondPart.split(':');
    //   let hour = parseInt(hourStr);
    //   let value = availability[index];
    //   if(!groupByHour.get(hour)) {
    //     groupByHour.set(hour, value);
    //   } else {
    //     groupByHour.set(hour, Math.min(value, groupByHour.get(hour) || 2000000));
    //   }
    //   index++;
    // }
    
    // const result = new Array<{hour:number, total:number, available:number}>();
    // groupByHour.forEach((value, key) => {
    //   result.push({hour: key, total: data?.totalEvses || 0, available: value});
    // });

    const groupByHalfanHour = new Map<string, number>();
    for(let objKey of Object.keys(data?.availableEvses)) {
      let [, hour] = objKey.split('T');
      let value = availability[index];
      if(!groupByHalfanHour.get(hour)) {
        groupByHalfanHour.set(hour, value);
      } else {
        groupByHalfanHour.set(hour, Math.min(value, groupByHalfanHour.get(hour) || 2000000));
      }
      index++;
    }
    
    const result = new Array<{hour:string, total:number, available:number}>();
    groupByHalfanHour.forEach((value, key) => {
      result.push({hour: key, total: data?.totalEvses || 0, available: value});
    });

    setHoursAvailable(result);

  }, [data, location]);

  function getDateFromHours(hours : string)
  {

    let [hourStr, minuteStr] = hours.split(':');
    let hour = parseInt(hourStr);
    let min = parseInt(minuteStr);
    var now = new Date();
    now.setMinutes(hour,min,0);

    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, min, 0);
    
    return today;

  }

  const availableItems = hoursAvailable.filter((t) => t.available > 0 
     && ((selected.getTime() === startOfDay(new Date()).getTime() && getDateFromHours(t.hour) > new Date())
         || selected.getTime() !== startOfDay(new Date()).getTime())
  );

  const chooseTimeline = (hourDate: Date)=> { 
    if(editMode) {
      onUpdate(hourDate);
      props.close();  
    }
    else {    
      open((props) => (
        <NewReservation location={location} date={hourDate} {...props} />
      ));}
  };

  return (
    <Dialog title={editMode ? t(intlKeys.UPDATE_RESERVATION) : t(intlKeys.NEW_RESERVATION)} {...props}>     
      <div className={styles.details}>
        <div className={styles.info}><LocationInfo location={location} /> </div>
        <div className = {styles.month}>{selected.getDate() ===  new Date().getDate()? t(intlKeys.TODAY) : visible.toLocaleString('default', { month: 'long' }) }</div>   
        <Calendar
          selected={selected}
          setSelected={setSelected}
          visible={visible}
          setVisible={setVisible}
          className={styles.calendar}
        />
      </div>
     
      <div className={styles.timeline}>
        <Timeline
          availability={pastDate ? [] : availableItems}
          dateDisplay= {selected}
          onClick={chooseTimeline}
        />
      </div>
    </Dialog>
  );
}
